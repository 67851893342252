/* ----------- import reset styles --------- */
@import "modern-css-reset/dist/reset.min.css";
@import "react-loading-skeleton/dist/skeleton.css";

/* ---------- import abstract folders ----------- */
@import "./abstracts/_variable.css";

/* ------------ import base folder ------------ */
@import "tailwindcss/components";
@import "./base/_base.css";
@import "./base/_typography.css";

/* ---------- import component folder ---------- */
@import "tailwindcss/components";

/* ----------- import utilities folder ---------- */
@import "tailwindcss/utilities";

@layer utilities {
  .error {
    color: var(--accent-4);
    font-size: 0.875rem;
    transition: 0.8s ease-in all;
  }
}

/* ------ imported component overwrite class ------ */
/* ------ calendar styles overwrite ------ */
.react-datepicker-popper {
  z-index: 3;
}

.react-datepicker,
.react-datepicker__month-container {
  background: var(--background-1) !important;
}

.react-datepicker__header,
.react-datepicker__month,
.react-datepicker__time,
.react-datepicker__month-dropdown,
.react-datepicker__year-dropdown {
  background: var(--background-1) !important;
  color: var(--text-light) !important;
}

.react-datepicker__day,
.react-datepicker__day-name,
.react-datepicker__month-read-view--selected-month,
.react-datepicker__year-read-view--selected-year,
.react-datepicker__current-month,
.react-datepicker-time__header {
  color: var(--text-light) !important;
}

.react-datepicker__time-list-item:hover,
.react-datepicker__day:hover,
.react-datepicker__month-option:hover,
.react-datepicker__year-option:hover {
  background: var(--background) !important;
  color: var(--text-light) !important;
}

.react-datepicker__time-list-item:hover {
  border-right: 1px solid #aeaeae;
}

/* ----- reach component styles overwrite for popover ------- */
[data-reach-menu-popover] {
  z-index: 2;
}

.react-loading-skeleton {
  background-color: var(--background-2) !important;
}

.react-loading-skeleton::after {
  background-image: var(--background-2) !important;
}
