@font-face {
  font-family: "DTonic";
  src: url("../../assets/fonts/cardtonic/Eina01-Light.ttf");
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "DTonic";
  src: url("../../assets/fonts/cardtonic/Eina01-Regular.ttf");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "DTonic";
  src: url("../../assets/fonts/cardtonic/Eina01-SemiBold.ttf");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "DTonic";
  src: url("../../assets/fonts/cardtonic/Eina01-Bold.ttf");
  font-weight: 700;
  font-display: swap;
}

body {
  font-weight: 400;
  font-family: "DTonic", sans-serif;
}
