:root {
  font-size: 16px;
}

body {
  background-color: var(--background);
}

main {
  background-color: var(--background);
  transition: all 0.5s;
  height: 100vh;
  overflow-y: auto;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
a,
div,
::placeholder {
  color: var(--text-light);
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

input[type="checkbox"] {
  accent-color: var(--accent-2);
}

* {
  scrollbar-color: var(--navigationBg) var(--background);
  scrollbar-width: thin;
}
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: var(--navigationBg);
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: var(--background-2);
}
[data-theme="dark"] {
  * {
    scrollbar-color: var(--neutralWhite) var(--background-2);
  }
}
